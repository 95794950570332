import React, { useState } from 'react';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/legacy/image';

import { t, translate } from '../../utils/ReactSwitchLangWrapper';
import { ExternalLink } from '../Link';
import { events } from '../../utils/Amplitude';
import style from '../../styles/AuthForm.module.scss';
import xeroIcon from '../../public/assets/XeroIcon.png';
import shopifyIcon from '../../public/assets/ShopifyIcon.png';
import { OAUTH_LINKS } from '../../utils/Constants';

const SHOPIFY = 0;
const XERO = 1;

function PlatformLogin() {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <h3 className={style.subtitle}>{t('Login_Subtitle_SupportedPlatform')}</h3>
      <ExternalLink
        href={OAUTH_LINKS.Shopify}
        className={`${style.oAuthBtn} ${style.oAuthBtnShopify}`}
        ampEvent={events.USER_CLICKED_LOGIN_BUTTON}
        ampEventProps={{ Platform: 'Shopify' }}
        target={undefined}
        onClick={() => setLoading(SHOPIFY)}
      >
        {loading === SHOPIFY ? (
          <FontAwesomeIcon
            icon={faSpinnerThird}
            spin
            className="fastSpin"
            size="1x"
          />
        ) : (
          <>
            <Image
              src={shopifyIcon}
              alt={t('ImageDescription_ShopifyIcon')}
              layout="fixed"
              width={30}
              height={30}
              unoptimized
            />
            <p>{t('Login_OAuthBtn_Shopify')}</p>
          </>
        )}
      </ExternalLink>
      <ExternalLink
        href={OAUTH_LINKS.Xero}
        className={`${style.oAuthBtn} ${style.oAuthBtnXero}`}
        ampEvent={events.USER_CLICKED_LOGIN_BUTTON}
        ampEventProps={{ Platform: 'Xero' }}
        target={undefined}
        onClick={() => setLoading(XERO)}
      >
        {loading === XERO ? (
          <FontAwesomeIcon
            icon={faSpinnerThird}
            spin
            className="fastSpin"
            size="1x"
          />
        ) : (
          <>
            <Image
              src={xeroIcon}
              alt={t('ImageDescription_XeroIcon')}
              layout="fixed"
              width={30}
              height={30}
              unoptimized
            />
            <p>{t('Login_OAuthBtn_Xero')}</p>
          </>
        )}
      </ExternalLink>
    </>
  );
}

export default translate(PlatformLogin);

import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setUser as setSentryUser } from '@sentry/nextjs';
import ReCAPTCHA from 'react-google-recaptcha';
import { Form } from 'react-final-form';

import { getLanguage, t, translate } from '../../utils/ReactSwitchLangWrapper';
import Button from '../Button';
import { Default, Password } from '../Field';
import Link from '../Link';
import GoogleRecaptchaDisclaimer from './GoogleRecaptchaDisclaimer';
import { events, logAmpEvent, setAmpUser } from '../../utils/Amplitude';
import { getReCaptchaToken, invalidFormAlert } from '../../utils/Helpers';
import { ExistingOAuthUserHandler, genCognitoErrHandler, useCognito } from '../../utils/Cognito';
import ErrorAlert from '../alerts/ErrorAlert';
import style from '../../styles/AuthForm.module.scss';
import { AUTH_API, AUTH_STEP } from '../../utils/Constants';
import { useRouter } from '../Router';
import { setScreenReaderAlert } from '../../utils/Accessibility';
import { setEmail } from '../../redux/actions/AuthActions';
import { isDevelopment, isProduction } from '../../utils/RuntimeEnv';
import { validateEmail } from '../../utils/Validation';
import verify from '../../utils/VerifySignature';
import { isLoadhubUserGroup } from '../../utils/UserGroup';

function LoginForm({ setStep }) {
  const dispatch = useDispatch();
  const cognito = useCognito();
  const router = useRouter();
  const [errorMsg, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const reCaptchaRef = useRef(null);

  const onSubmit = (async (values) => {
    setLoading(true);
    const email = values.email.toLowerCase().trim();

    let recaptchaToken;
    // optionally skip reCAPTCHA on test server for automated testing
    // window.emailSignature will be set by selenium during automated testing
    // the signature would've been generated with a private key
    if (!isDevelopment || !window.emailSignature || !await verify(window.emailSignature, email)) {
      recaptchaToken = getReCaptchaToken(reCaptchaRef.current, () => setLoading(false));
      if (!recaptchaToken) return;
    }

    cognito
      .authenticate(email, values.password, {
        recaptchaToken,
      })
      .then(() => {
        dispatch(setEmail(email));
        setScreenReaderAlert(t('ScreenReaderAlert_LoginSuccess'));
        // identify user for analytics
        setAmpUser(email);
        if (isProduction) setSentryUser({ email });
        logAmpEvent(events.API_SUCCESS, { Call: `Cognito: ${AUTH_API.LOGIN}` });
        if (isLoadhubUserGroup()) {
          // navigate right to reports-and-refunds to prevent browser rerouting
          router.pushStatic(`/${getLanguage()}/reports-and-refunds`);
        } else {
          router.pushStatic(`/${getLanguage()}/home`);
        }
        setSuccessful(true);
      })
      .catch(
        genCognitoErrHandler(AUTH_API.LOGIN, setError, {
          UserNotConfirmedException: () => {
            dispatch(setEmail(values.email));
            setScreenReaderAlert(t('ScreenReaderAlert_ConfirmEmailSent'));
            setStep(AUTH_STEP.CONFIRM_EMAIL);
            return true;
          },
          UserLambdaValidationException: ExistingOAuthUserHandler,
        })
      )
      .finally(() => setLoading(false));
  });

  function recaptchaFailedCallback() {
    logAmpEvent(events.GOOGLE_RECAPTCHA_ERROR);
    setError(t('GoogleRecaptchaFailureMsg'));
    setLoading(false);
  }

  const validate = (values) => {
    const errors = {};
    errors.email = validateEmail(values.email);
    if (!values.password) {
      errors.password = t('Validation_Required');
    }
    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={(values) => validate(values)}
      render={({ handleSubmit, invalid, errors, form: { getFieldState, submit } }) => (
        <form
          onSubmit={(event) => {
            invalidFormAlert(invalid, errors);
            handleSubmit(event);
          }}
        >
          <ReCAPTCHA
            style={{ display: 'none' }}
            theme="dark"
            ref={reCaptchaRef}
            size="invisible"
            sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY}
            onErrored={() => { recaptchaFailedCallback(); }}
            onChange={submit}
          />
          <ErrorAlert msg={errorMsg} fullWidth forgotPassword>
            <Link
              className={style.forgotPasswordLink}
              href="/forgot-password"
              title={t('LinkDescription_ForgotPassword')}
              ampEvent={events.USER_CLICKED_FORGOT_PASSWORD}
              onClick={() => {
                dispatch(setEmail(getFieldState('email').value));
              }}
            >
              {t('Login_ForgotPassword_Link')}
            </Link>
          </ErrorAlert>
          <h2 className={style.formTitle}>{t('Login_Title')}</h2>
          <h3 className={style.subtitle}>{t('Login_Subtitle_PartnerAccount')}</h3>
          <Default
            label={t('Auth_Field_Email_Lbl')}
            id="EmailInput"
            name="email"
            autoComplete="email"
            placeholder={t('Auth_Field_Email_Placeholder')}
          />
          <Password
            label={t('Auth_Field_Password_Lbl')}
            id="PasswordInput"
            name="password"
          />
          <Button
            text={t('Login_SignIn_Btn')}
            type="submit"
            ampEvent={events.USER_CLICKED_LOGIN_BUTTON}
            loading={loading}
            successful={successful}
            className={`${style.fullButton} ${style.authButton}`}
            onClick={() => {
              setError('');
            }}
          />
          <GoogleRecaptchaDisclaimer />
          <p className={style.altLink}>
            {`${t('Login_NeedAccount_Lbl')} `}
            <Link
              href="/register"
              ampEvent={events.USER_CLICKED_REGISTER_LINK_FROM_LOGIN}
            >
              {t('Login_CreateAccount_Link')}
            </Link>
          </p>
        </form>
      )}
    />
  );
}

export default translate(LoginForm);
